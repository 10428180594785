import React, { useState, useEffect, useCallback, useRef } from 'react';

import { TitleRow, ContainerRow, CloseButton } from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import PageTitle from '../../../Utils/PageTitle';

import { SEARCH_DELAY } from '../../../Constants/StringConstants';

import AddKeyToPhraseForm from './AddKeyToPhraseForm';
import CustomSideDrawer from '../../CustomComponents/CustomSideDrawer';

import {
  saveCannedText,
  getCannedtextCollections,
  saveCannedTextActiveList,
  removeCannedTextActiveList,
  authorsCollectionToMyCollection,
  editCannedText,
  removeCannedTexts,
  templateMappingStatus,
  targetEndPoints
} from '../../../Apis/Author';
import {
  notification,
  Row,
  Col,
  Checkbox,
  message,
  Button,
  Divider,
  Tooltip
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  ArrowDownOutlined,
  DownloadOutlined
} from '@ant-design/icons';

import DeleteIcon from '../../../Assets/Icons/DeleteIcon';
import { useDebounce } from '../../../Utils/Debounce';
import { AddButton } from '../styledComponents';
import BreadCrumbComponent from './../../CustomComponents/BreadCrumbComponent';
import {
  SubmitCol,
  KpCol,
  ActiveKeytophraseCol,
  CardTitle,
  StyledSelect,
  StyledDiv,
  DnDColumn,
  CustomModal,
  Keytophraseduplication,
  K2pButton,
  Topdivide,
  K2pButtonsecnd,
  ActiveSectionCol,
  SearchInputStylee,
  KeytophraseMineCol,
  SearchDivider,
  CustomModalforAddk2p
} from './styledComponents';
import { SearchInputStyle } from '../styledComponents';
import { DeleteConfirmationMessage } from '../Dictation/styledComponents';
import { getUserFacilities } from '../../../Apis/User';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { COLUMN_NAMES, Column, MovableItem } from './DragAndDropComponent';
import Spinner from '../../CustomComponents/Spinner';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';

const KeyToPhrase = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [searchString, setSearchString] = useState('');
  const [total, setTotal] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const [fetchDataFlagMine, setFetchDataFlagMine] = useState(true);
  const [fetchDataFlagAuthor, setFetchDataFlagAuthor] = useState(true);
  const [fetchDataFlagActive, setFetchDataFlagActive] = useState(true);
  const [cannedText, setCannedTexts] = useState([]);
  const [cannedTextAuthor, setCannedTextsAuthor] = useState([]);
  const [cannedTextActive, setCannedTextsActive] = useState([]);
  const [cannedTextMine, setCannedTextsMine] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [k2pMapping, setK2pMapping] = useState([]);
  const [cannedTextMineSize, setCannedTextsMineSize] = useState(0);
  const [cannedTextAuthorSize, setCannedTextAuthorSize] = useState(0);
  const [cannedTextActiveSize, setCannedTextActiveSize] = useState(0);
  const [checkedCards, setCheckedCards] = useState([]);
  const [dynamicEndPoints, setDynamicEndPoints] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [toActive, setToActive] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [checkedCardIds, setCheckedCardIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTree, setLoadingTree] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingActiveScroll, setLoadingActiveScroll] = useState(false);
  const [loadingMineScroll, setLoadingMineScroll] = useState(false);
  const [loadingAuthor, setLoadingAuthor] = useState(false);
  const [addDrawerVisible, setAddDrawerVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [treeVisible, setTreeVisible] = useState(
    selectedItem ? selectedItem.restrictedToTemplates : false
  );

  const [selectedItemColumn, setSelectedItemColumn] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filterKey, setFilterKey] = useState('name');
  const [sortOrder, setSortOrder] = useState('');
  const [facilityIds, setFacilityIds] = useState();
  const [overRideExisting, setOverRideExisting] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { Option } = StyledSelect;
  const toggleTable = () => {
    setK2pMapping([]);
    setShowSelectedPhrase(false);
    setSelectedItem(null);
  };
  const handleCreate = () => {
    setK2pMapping([]);
    fetchAll();
    setSelectedItem(null);
    setShowSelectedPhrase(true);
    if (!selectedItem) {
      setTreeVisible(false);
    }
  };
  const [minePage, setMinePage] = useState(0);
  // const [minePageSearch, setMinePageSearch] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryAuthor, setSearchQueryAuthor] = useState('');
  // const [cannedTextMineSearch, setCannedTextsMineSearch] = useState([]);
  // const [cannedTextsMineSearchSize, setCannedTextsMineSearchSize] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [authPage, setAuthPage] = useState(0);
  const [keyToPhraseItems, setKeyToPhraseItems] = useState([]);
  const [selectedFacilityItem, setSelectedFacilityItem] = useState([]);
  const [showSelectedPhrase, setShowSelectedPhrase] = useState(false);
  const [selectedPhrase, setSelectedPhrase] = useState();
  const { KEY_TO_PHRASE, ACTIVE_KEY_TO_PHRASE } = COLUMN_NAMES;
  const [loader, setLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const [hasMore, setHasMore] = useState(true); // State to indicate if there are more items to load
  // const [items, setItems] = useState([]); // State to hold the items
  // const [data, setData] = useState([]); // State to hold fetched data
  let appendedData = [];
  const handleScroll = e => {
    e.persist();

    if (
      e.target.scrollHeight - e.target.scrollTop <
      e.target.clientHeight + 5
    ) {
      fetchMoreData();
    }
  };
  const handleMineDelete = () => {
    setDeleteModal(true);
  };
  const handleScrollAuthor = e => {
    e.persist();

    if (
      e.target.scrollHeight - e.target.scrollTop <
      e.target.clientHeight + 2
    ) {
      fetchMoreDataAuthor();
    }
  };
  const handleScrollActive = e => {
    // e.persist();

    if (
      e.target.scrollHeight - e.target.scrollTop <
      e.target.clientHeight + 1
    ) {
      fetchMoreDataActive();
    }
  };
  const toggleActive = () => {
    setToActive(!toActive);
    setDeleteModal(false);
  };
  const handleFormValuesChange = (changedValues, allValues) => {
    if (selectedItem) {
      for (const key in changedValues) {
        if (typeof changedValues[key] === 'string') {
          if (selectedItem[key] !== null) {
            if (
              removeHTMLTags(selectedItem[key]) !==
              removeHTMLTags(changedValues[key])
            ) {
              setSubmitDisabled(true);
            } else {
              setSubmitDisabled(false);
            }
          }
        } else {
          if (selectedItem[key] !== null) {
            if (selectedItem[key] !== changedValues[key]) {
              setSubmitDisabled(true);

              // Perform any action you want upon detecting a change to the same value
            } else {
              setSubmitDisabled(false);
            }
          }
        }
      }
    }
  };
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  const debouncedHandleScrollActive = debounce(handleScrollActive, 300);
  window.addEventListener('scroll', debouncedHandleScrollActive);

  const fetchMoreData = () => {
    if (loadingMineScroll) {
      return;
    }
    if (cannedTextMineSize > cannedTextMine.length) {
      setLoadingMineScroll(true);
      fetchMineData();
    }
  };
  const fetchMoreDataAuthor = () => {
    if (loading) {
      return;
    }
    if (cannedTextAuthorSize > cannedTextAuthor.length) {
      // setLoading(true);
      fetchAuthorData();
    }
  };
  const fetchMoreDataActive = () => {
    if (loadingActiveScroll) {
      return;
    }
    if (cannedTextActiveSize > cannedTextActive.length) {
      // setLoading(true);

      fetchActiveData();
      setLoadingActiveScroll(true);
    }
  };

  useEffect(() => {
    setFetchDataFlagMine(true);
  }, [searchQuery]);
  useEffect(() => {
    if (!initialRender) {
      if (authPage === 0 && fetchDataFlagAuthor) {
        setFetchDataFlagAuthor(false);

        fetchAuthorData();
      }
    } else {
      setInitialRender(false);
    }
  }, [searchQueryAuthor, fetchDataFlagAuthor, authPage, cannedTextAuthor]);
  let timerId1;

  const handleSearch = event => {
    clearTimeout(timerId1);
    const { value } = event.target;
    timerId1 = setTimeout(async () => {
      try {
        setCannedTextsMine([]);
        setMinePage(0); // Reset page when performing a new search
        setSearchQuery(value);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 1000);
  };

  const handleSearchAuthor = event => {
    clearTimeout(timerId1);

    const { value } = event.target;

    // Reset page when performing a new search
    timerId1 = setTimeout(async () => {
      try {
        setCannedTextsAuthor([]);
        setAuthPage(0);
        setSearchQueryAuthor(value);
        setFetchDataFlagAuthor(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 1000);
  };

  const handleOpenModal = (e, id) => {
    e.stopPropagation(); // Stop event propagation to prevent triggering the parent click event
    const removeItem = cannedTextActive.find(item => item.id === id);
    setSelectedItem(removeItem);
    setIsModalVisible(true);
  };
  // Function to handle confirming the action and closing the modal
  const handleConfirmAction = e => {
    handleRemoveFromSelected(e, selectedItem.id);
  };

  // Function to handle canceling the action and closing the modal
  const handleCancelAction = () => {
    setIsModalVisible(false);
  };

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = cannedTextMine[dragIndex];

    if (dragItem) {
      setKeyToPhraseItems(prevState => {
        const coppiedStateArray = [...prevState];
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

        return coppiedStateArray;
      });
    }
  };
  const KeyPhraseCard = ({
    id,
    facilityKey,
    phrase,
    cardClosable = false,
    handleItemClick
  }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <StyledDiv
          width='100%'
          className='movable-item1'
          direction='column'
          onClick={handleItemClick}
        >
          <StyledDiv width='100%' justify={'space-between'}>
            <h4 className='keyvalue'>
              <TruncatedText text={facilityKey} maxLength={80} />
            </h4>
            {cardClosable && (
              <CloseButton
                onClick={e => handleOpenModal(e, id)}
                icon={<CloseOutlined />}
              />
            )}

            <ConfirmationModal
              visible={isModalVisible}
              onOk={e => handleConfirmAction(e)}
              onCancel={handleCancelAction}
              // maskStyle={{ backgroundColor: 'transparent' }}

              centered
              // loading={deleteLoading}
              confirmation={true}
              antdModalProps={{ closable: false, mask: false }}
            >
              <DeleteIcon />
              <DeleteConfirmationMessage>
                Are you sure you want to delete?
              </DeleteConfirmationMessage>
            </ConfirmationModal>
          </StyledDiv>
          <p className='phrasevalue'>
            <TruncatedText text={removeHTMLTags(phrase)} maxLength={100} />
          </p>
        </StyledDiv>
      </div>
    );
  };
  useEffect(() => {}, [loadingTree]);
  let updatedK2pMappingkeys = [];
  const toggleTreeVisibility = (newValue, templateMapping) => {
    setLoadingTree(true);
    if (newValue === true) {
      templateMappingStatus()
        .then(response => {
          if (response.data.success) {
            if (templateMapping) {
              templateMapping.forEach(templateItem => {
                const k2pItemIndex = response.data.result.findIndex(
                  item => item.id === templateItem.templateId
                );

                if (k2pItemIndex !== -1) {
                  response.data.result[k2pItemIndex].selected =
                    templateItem.allHeadings;
                  if (templateItem.allHeadings !== true) {
                    response.data.result[k2pItemIndex].sectionHeadings.forEach(
                      sectionHeading => {
                        if (
                          templateItem.templateHeadings.includes(
                            sectionHeading.customUIHeading
                          )
                        ) {
                          sectionHeading.selected = true;
                        }
                      }
                    );
                  } else {
                    response.data.result[k2pItemIndex].sectionHeadings.map(
                      sectionHeading => {
                        sectionHeading.selected = true;
                      }
                    );
                  }
                }
              });
            }

            let updatedK2pMapping = response.data.result.map(
              (template, templateIndex) => {
                template.sectionHeadings = template.sectionHeadings.map(
                  (heading, headingIndex) => {
                    // Generate the key
                    const id = `0-${templateIndex}-${headingIndex}`;

                    // Set the key to the sectionHeading
                    if (heading.selected) {
                      updatedK2pMappingkeys.push(id);
                    }
                    return { ...heading, id };
                  }
                );

                return template;
              }
            );

            setK2pMapping(updatedK2pMapping);
            setLoadingTree(false);

            // notification['success']({
            //   message: 'Success',
            //   description: response.data.message
            // });
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })

        .catch(error => {
          // Handle errors if needed
        });
      setTreeVisible(newValue);
    } else {
      setLoadingTree(false);
      setTreeVisible(newValue);
    }
  };

  const handleRemoveFromSelected = (e, id) => {
    e.stopPropagation();
    // setIsModalVisible(true);
    removeCannedTextActiveList(id).then(res => {
      if (res?.data?.success) {
        notification.success({ message: res?.data?.message });
        setShowSelectedPhrase(false);
        setIsModalVisible(false);
        const data = cannedTextActive.filter(item => item.id !== id);
        setCannedTextsActive(data);
        const removedItem = cannedTextActive.find(item => item.id === id);

        removedItem.active = false;
        setCannedTextsMine(prevItems => [removedItem, ...prevItems]);
        // setFetchDataFlagMine(true);
        setMinePage(0);

        // fetchMineData();
        setActivePage(0);
        // fetchActiveData();
        // fetchData();
      } else {
        notification.error({ message: res?.data?.message });
      }
    });
  };

  const saveCannedTextToFacility = item => {
    saveCannedTextActiveList(item.data.id).then(res => {
      if (res?.data?.success) {
        notification.success({ message: res?.data?.message });

        const data = cannedTextMine.filter(
          cannedItem => cannedItem.id !== item.data.id
        );
        setCannedTextsMine(data);

        setMinePage(0);

        setActivePage(0);
        // setActivePage(0);
        // fetchActiveData();
        // setCannedTextsMine(data);
      } else {
        notification.error({ message: res?.data?.message });
      }
    });
  };

  const returnItemsForColumn = columnName => {
    return cannedTextActive.map((item, index) => (
      <KeyPhraseCard
        key={index}
        id={item.id}
        facilityKey={item.key}
        phrase={item.phrase}
        cardClosable={true}
        handleItemClick={() => handleItemClick(item, 'Active')}
        saveCannedTextToFacility={() => saveCannedTextToFacility()}
        style={{ backgroundColor: 'white' }}
      />
    ));
  };

  const TruncatedText = ({ text, maxLength }) => {
    if (typeof text === 'string' && text.length > maxLength) {
      const truncatedText = text.substring(0, maxLength) + '...';
      return <span>{truncatedText}</span>;
    }
    return <span>{text}</span>;
  };
  const handleActiveClickChange = () => {
    submitHandler();
  };
  const handleActiveClick = () => {
    setCannedTextsActive(prevState => [selectedItem, ...prevState]);
    saveCannedTextActiveList(selectedItem.id).then(res => {
      if (res?.data?.success) {
        notification.success({ message: res?.data?.message });

        const data = cannedTextMine.filter(
          cannedItem => cannedItem.id !== selectedItem.id
        );
        setCannedTextsMine(data);
        setCannedTextsActive([]);
        setCannedTextActiveSize(0);
        setActivePage(0);
        setFetchDataFlagActive(true);
        setCurrentPage(0);
        toggleActive();
      } else {
        notification.error({ message: res?.data?.message });
        toggleActive();
      }
    });
    setShowSelectedPhrase(false);
  };

  useEffect(() => {
    let newSelectedFields = [];

    k2pMapping
      .filter(item => {
        return item.selected;
      })
      .filter(item => {
        return item.selected;
      })
      .forEach(item => {
        newSelectedFields.push(item);
      });

    k2pMapping
      .filter(item => {
        return (
          item.selected === false &&
          item.sectionHeadings.some(heading => heading.selected)
        );
      })
      .forEach(item => {
        item.sectionHeadings
          .filter(subItem => {
            return subItem.selected;
          })
          .forEach(subItem => {
            newSelectedFields.push(subItem);
          });
      });

    setSelectedFields(newSelectedFields);
  }, [k2pMapping]);
  const handleCheck = (checkedKeys, { node }) => {
    setSubmitDisabled(true);

    setK2pMapping(
      k2pMapping.map(item => {
        if (item.id === node.key) {
          // Toggle selected for the entire item and its sectionHeadings
          item.sectionHeadings = item?.sectionHeadings?.map(subItem => {
            subItem.selected = !subItem.selected;
            return subItem;
          });
          item.selected = !item.selected;
        } else {
          item.sectionHeadings = item?.sectionHeadings?.map(subItem => {
            // Check if the subItem key matches node.key
            if (subItem.id === node.key) {
              // Toggle the selected property
              subItem.selected = !subItem.selected;
            }
            return subItem; // Ensure to return the modified subItem
          });
          if (item.sectionHeadings.some(heading => !heading.selected)) {
            item.selected = false;
          } else {
            item.selected = true;
          }
        }
        return item; // Ensure to return the modified item
      })
    );
  };

  const handleDeleteClick = () => {
    setSubmitLoading(true);

    removeCannedTexts(selectedItem.id)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setDeleteModal(false);
          setSubmitLoading(false);
          setShowSelectedPhrase(false);
          fetchData();
          if (selectedItem.active) {
            const data = cannedTextActive.filter(
              cannedItem => cannedItem.id !== selectedItem.id
            );

            setCannedTextsActive(data);
          } else {
            const data = cannedTextMine.filter(
              cannedItem => cannedItem.id !== selectedItem.id
            );

            setCannedTextsMine(data);
          }
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setDeleteModal(false);
          setSubmitLoading(false);
          setShowSelectedPhrase(false);
        }
      })

      .catch(err => {
        setSubmitLoading(false);
        console.log(err);
      });
  };
  const returnItemsForColumn1 = columnName => {
    return (
      cannedTextMine
        // .filter(item => item.column === columnName)
        .map((item, index) => {
          return (
            <MovableItem
              key={item.id}
              id={item.id}
              name={
                <StyledDiv direction='column'>
                  <h4 className='keyvalue'>
                    <TruncatedText text={item.key} maxLength={60} />
                  </h4>
                  <p className='phrasevalue'>
                    <TruncatedText
                      text={removeHTMLTags(item.phrase)}
                      maxLength={60}
                    />
                  </p>
                </StyledDiv>
              }
              currentColumnName={item.column}
              setItems={setCannedTextsActive}
              saveCannedTextToFacility={saveCannedTextToFacility}
              index={index}
              moveCardHandler={moveCardHandler}
              handleItemClick={() => handleItemClick(item, 'Mine')}
              data={item}
              className={columnName === 'Column1' ? 'column1-item' : ''}
            />
          );
        })
    );
  };

  let BreadCrubItems = [
    {
      name: 'Settings',
      path: '/dashboard/settings'
    },
    {
      name: 'Key to Phrase',
      path: '/dashboard/settings/key-to-phrase',
      onClick: () => {
        setShowTable(true);
      }
    }
  ];
  if (!showTable) {
    BreadCrubItems.push({
      name: 'Configure'
    });
  }
  const handleSkip = () => {
    handleImport();
    // setCannedTextsAuthor([]);
    setAuthPage(0);
  };
  const handleReplace = () => {
    handleImport(true);
    // setCannedTextsAuthor([]);
    setAuthPage(0);
  };

  const submitHandler = values => {
    values.phrase = values.phrase
      .replace(/<\/p>/g, '</p><br>')
      .replace(/<p><br><\/p><br>/g, '<br>')
      .replace(/<br>$/g, '');
    values.targetEndPoint =
      values.targetEndPoint === '' || values.dynamic == false
        ? null
        : values.targetEndPoint;
    // values.exportToOtherAuthors = value.exportToOtherAuthors;
    values.templateMapping = k2pMapping
      .map(item => {
        const allHeadings = item.selected;
        const templateHeadings = item.sectionHeadings
          .filter(heading => heading.selected)
          .map(heading => heading.customUIHeading);
        return {
          allHeadings,
          templateHeadings,
          templateId: item.id
        };
      })
      .filter(item => item.templateHeadings.length > 0);
    setSubmitLoading(true);
    const payload = values;

    if (selectedItem) {
      payload.id = selectedItem.id;
      editCannedText(payload)
        .then(response => {
          if (response.data.success) {
            notification['success']({
              message: 'Success',
              description: response.data.message
            });

            setSubmitLoading(false);
            setShowSelectedPhrase(false);

            // fetchData();
            Object.keys(payload).forEach(key => {
              if (selectedItem.hasOwnProperty(key)) {
                selectedItem[key] = payload[key];
              }
            });
            setSelectedItem(null);
            if (payload.active == true) {
            }
            if (payload.active == false) {
            }
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setSubmitLoading(false);
            // setShowSelectedPhrase(false);
          }
        })
        .catch(err => {
          setSubmitLoading(false);
          console.log(err);
        });
    } else {
      saveCannedText(payload)
        .then(response => {
          if (response.data.success) {
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
            setSubmitLoading(false);
            toggleTable(false);
            setSelectedItem(null);
            // fetchMineData();
            setCannedTextsMine([]);
            setCannedTextsMineSize(0);
            setMinePage(0);
            setFetchDataFlagMine(true);
            setCurrentPage(0);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setSubmitLoading(false);
            // toggleTable(false);
          }
        })
        .catch(err => {
          setSubmitLoading(false);
          console.log(err);
        });
    }
  };

  const fetchAll = pageNo => {
    // setLoading(true);
    targetEndPoints()
      .then(response => {
        setLoading(false);
        if (response.data.success) {
          setTotal(response.data.size);
          setCannedTexts(response.data.result);
          setDynamicEndPoints(response.data.result);
          const keys = response.data.result.map(item => {
            return { ...item, column: COLUMN_NAMES.KEY_TO_PHRASE };
          });

          setKeyToPhraseItems(keys);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const filterTableChange = (pagination, filters, sorter) => {
    if (sorter.field !== undefined)
      setFilterKey(sorter.field && sorter.field[0]);
    if (sorter.order !== undefined) setSortOrder(sorter.order);
  };

  const onChangeHandler = value => {
    setCurrentPage(0);
    setSearchString(value);
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY),
    []
  );

  // const pageNoChangeHandler = pageNo => {
  //   fetchAll(pageNo);
  // };
  const handleItemClick = (item, type) => {
    setToActive(false);
    fetchAll();
    setSelectedItemColumn(type);
    setSelectedItem(item);
    updatedK2pMappingkeys = [];
    toggleTreeVisibility(
      item && item.restrictedToTemplates,
      item && item.templateMapping
    );

    if (!isModalVisible) {
      setShowSelectedPhrase(true);
    }
  };
  const handleCancel = () => {
    setModalVisible(false);
    setSelectedCard(null);
    setSearchQueryAuthor('');
    setCannedTextsAuthor([]);
    setAuthPage(0);
    setCheckedCards([]);
    setCheckedCards([]);
  };
  const toggleErrorModal = () => {
    setErrorModalVisible(!errorModalVisible);
    // setCannedTextsAuthor([]);
    setAuthPage(0);
  };
  const activeItems = returnItemsForColumn(ACTIVE_KEY_TO_PHRASE);
  // fetchAllCannedText = () => {};
  const getFacilities = () => {
    getUserFacilities().then(response => {
      if (response.data.success) {
        const enabledFacilities = response.data.result.filter(facility => {
          return !facility.disabled;
        });
        setFacilities(enabledFacilities);
        setFacilityIds(enabledFacilities[0]?.facilityId);
      } else {
        notification['error']({
          message: 'User Facilities Fetch Failed',
          description: response.data.message
        });
      }
    });
  };
  const fetchAuthorData = async () => {
    setFetchDataFlagAuthor(false);

    setModalVisible(true);
    setLoadingAuthor(true);

    try {
      const mineRes = await getCannedtextCollections(
        authPage,
        50,
        'author',
        searchQueryAuthor
      );

      const keys = mineRes.data.result.map(item => ({
        ...item
      }));
      const filteredKeys = keys.filter(
        newItem => !cannedTextAuthor.some(oldItem => oldItem.id === newItem.id)
      );

      setCannedTextsAuthor(prevItems => [...prevItems, ...filteredKeys]);

      setCannedTextAuthorSize(mineRes.data.size);

      if (mineRes.data.result.length == 50) {
        setAuthPage(prevPage => prevPage + 1);
      }

      setLoadingAuthor(false);
    } catch (error) {
      setLoadingAuthor(false);
    }
  };

  const fetchActiveData = async () => {
    try {
      const mineRes = await getCannedtextCollections(activePage, 10, 'active');

      const keys = mineRes.data.result.map(item => ({
        ...item
      }));
      const filteredKeys = keys.filter(
        newItem => !cannedTextActive.some(oldItem => oldItem.id === newItem.id)
      );

      setCannedTextsActive(prevItems => [...prevItems, ...filteredKeys]);
      setCannedTextActiveSize(mineRes.data.size);
      if (mineRes.data.result.length == 10) {
        setActivePage(prevPage => prevPage + 1);
      }
      setLoadingActive(false);
      setLoadingActiveScroll(false);
    } catch (error) {
      setLoadingActive(false);
      setLoadingActiveScroll(false);
    }
  };
  const fetchMineData = async () => {
    try {
      const mineRes = await getCannedtextCollections(
        minePage,
        10,
        'mine',
        searchQuery
      );
      // if (mineRes.data.size == 0) {
      //   notification.warning({
      //     message: 'No data available to show in my collection'
      //   });
      // }
      const keys = mineRes.data.result.map(item => ({
        ...item,
        column: COLUMN_NAMES.KEY_TO_PHRASE
      }));
      const filteredKeys = keys.filter(
        newItem => !cannedTextMine.some(oldItem => oldItem.id === newItem.id)
      );

      setCannedTextsMine(prevItems => [...prevItems, ...filteredKeys]);
      setCannedTextsMineSize(mineRes.data.size);
      if (mineRes.data.result.length == 10) {
        setMinePage(prevPage => prevPage + 1);
      }
      setLoadingMineScroll(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLoadingMineScroll(false);
    }
  };

  useEffect(() => {}, [cannedTextAuthor]);
  useEffect(() => {
    if (minePage === 0 && fetchDataFlagMine) {
      setLoading(true);
      fetchMineData();
      setFetchDataFlagMine(false);
    }
    if (activePage === 0 && fetchDataFlagActive) {
      setLoadingActive(true);
      fetchActiveData();
      setFetchDataFlagActive(false);
    }
  }, [minePage, activePage, fetchDataFlagMine, fetchDataFlagActive]);

  const fetchData = () => {};

  function removeHTMLTags(inputString) {
    if (inputString) {
      return inputString.replace(/<[^>]*>/g, '');
    }
  }
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const isConflict = () => {
    const isItemAlreadyPresent = checkedCardIds.some(cardId => {
      const card = cannedTextAuthor.find(item => item.id === cardId);
      return card && card.conflict === true;
    });

    if (isItemAlreadyPresent) {
      toggleErrorModal();
      return;
    } else {
      // setCannedTextsAuthor([]);
      handleImport();
    }
  };
  const handleImport = (overRideExisting = false) => {
    authorsCollectionToMyCollection(checkedCardIds, overRideExisting).then(
      res => {
        if (res?.data?.success) {
          notification.success({ message: res?.data?.message });
          setModalVisible(false);
          setCheckedCards([]);
          setCheckedCardIds([]);
          setSelectedCard(null);
          setErrorModalVisible(false);
          setCannedTextsMine([]);
          setCannedTextsMineSize(0);
          setMinePage(0);
          setFetchDataFlagMine(true);
          // fetchMineData();
        } else {
          notification.error({ message: res?.data?.message });
        }
      }
    );
  };
  const phraseWithoutPTags =
    selectedCard && selectedCard.phrase
      ? selectedCard.phrase
          .replace(/<p><br><\/p><br>/g, '<p>') // Replace <br><p> with <p>
          .replace(/<p>/g, '') // Remove <p> tags
          .replace(/<\/p>/g, '') // Remove </p> tags
      : '';

  useEffect(() => {
    setSelectedCard(cannedTextAuthor[0]);
  }, [cannedTextAuthor]);

  useEffect(() => {}, [cannedTextAuthor]);
  const fetchCannedTexts = type => {};
  const handleCardClick = card => {
    setSelectedCard(card);
  };
  const removeSpaces = str => {
    // Remove all spaces from a string
    return str.replace(/\s/g, '');
  };
  const handleCheckboxChange = (card, checked) => {
    if (checked) {
      const similarKeyItem = checkedCards.find(
        item => removeSpaces(item.key) === removeSpaces(card.key)
      );
      if (similarKeyItem) {
        message.error('This card is already checked!');
      } else {
        setCheckedCards([...checkedCards, card]);
      }
    } else {
      // If the checkbox is unchecked, remove the item from the checkedCards array
      setCheckedCards(checkedCards.filter(item => item.id !== card.id));
    }
    if (checkedCardIds.includes(card.id)) {
      setCheckedCardIds(checkedCardIds.filter(id => id !== card.id));
    } else {
      setCheckedCardIds([...checkedCardIds, card.id]);
    }
  };
  return (
    <>
      <PageTitle title='Key to Phrase' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            Key to Phrase
          </CustomTypography>
          <div>
            <K2pButtonsecnd
              className='blue-button'
              shape='round'
              onClick={fetchAuthorData}
              icon={<ArrowDownOutlined className='blue-button' />}
            >
              Import From Global List
            </K2pButtonsecnd>
            <K2pButton
              icon={<PlusOutlined />}
              type='primary'
              shape='round'
              onClick={handleCreate}
              // onClick={() => handleCreate}
              style={{ marginRight: !showTable ? '60px' : '10px' }}
            >
              Create New K2P
            </K2pButton>

            <CustomModal
              title='Import Key-to-Phrases'
              className='customModalTitle'
              visible={modalVisible}
              onCancel={handleCancel}
              bodyStyle={{}}
              footer={[
                <AddButton key='cancel' shape='round' onClick={handleCancel}>
                  Cancel
                </AddButton>,
                <AddButton
                  key='import'
                  type='primary'
                  icon={<DownloadOutlined />}
                  shape='round'
                  onClick={isConflict}
                  disabled={!checkedCards || checkedCards.length === 0}
                >
                  Import selected
                </AddButton>
              ]}
            >
              <Row gutter={[16, 16]} style={{ marginRight: '2px' }}>
                <Col span={9}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative'
                    }}
                  >
                    <SearchInputStylee
                      placeholder='Search Keys or Phrases'
                      prefix={<SearchOutlined />}
                      // value={searchQueryAuthor}
                      onChange={handleSearchAuthor}
                      style={{
                        marginBottom: '10px'
                      }}
                    />
                    <SearchDivider></SearchDivider>

                    {loadingAuthor ? (
                      <Spinner height={'52vh'} />
                    ) : cannedTextAuthor == 0 ? (
                      <div
                        className='noData'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flex: 1
                        }}
                      >
                        No data available
                      </div>
                    ) : (
                      <div
                        style={{
                          maxHeight: '440px',
                          overflowY: 'auto',
                          marginLeft: '19px'
                        }}
                        onScroll={handleScrollAuthor}
                      >
                        {cannedTextAuthor.map(item => (
                          <KpCol
                            key={item.key}
                            onClick={() => handleCardClick(item)}
                            className='card-item'
                            // style={{ backgroundColor: '#f5f7fa ' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '6px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline'
                                }}
                              >
                                <Checkbox
                                  checked={checkedCards.some(
                                    data => data.id === item.id
                                  )}
                                  onChange={e =>
                                    handleCheckboxChange(item, e.target.checked)
                                  }
                                />
                                <h3 className='keyvalue'>
                                  <TruncatedText
                                    text={item.key}
                                    maxLength={30}
                                  />
                                </h3>
                              </div>
                              <Tooltip
                                placement='topRight'
                                title={
                                  <div>
                                    <ExclamationCircleOutlined
                                      style={{ color: 'orange' }}
                                    />
                                    <span style={{ marginLeft: 8 }}>
                                      A K2P with this name already exist in your
                                      collection
                                    </span>
                                  </div>
                                }
                              >
                                {item.conflict && (
                                  <ExclamationCircleOutlined
                                    style={{
                                      color: '#E17F37',
                                      marginLeft: 'auto'
                                    }}
                                  />
                                )}
                              </Tooltip>
                            </div>
                            <div className='author-box'>
                              By: {item.author.doctorName}
                            </div>{' '}
                            <p style={{ marginBottom: '6px' }}>
                              <TruncatedText
                                text={removeHTMLTags(item.phrase)}
                                maxLength={30}
                              />
                            </p>
                          </KpCol>
                        ))}
                      </div>
                    )}
                  </div>
                  <Divider
                    type='vertical'
                    style={{
                      height: '100%',
                      position: 'absolute',
                      right: 0,
                      top: 0
                    }}
                  />
                </Col>

                {/* Right column */}
                <Col
                  span={15}
                  style={{ backgroundColor: '#F5F7FA', height: '490px' }}
                >
                  {selectedCard && (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col span={24} style={{ marginTop: '12px' }}>
                          <h5
                            className='header'
                            style={{
                              marginLeft: 8,
                              fontFamily: 'Krub',
                              fontWeight: 400,
                              fontSize: '14px'
                            }}
                          >
                            Key
                          </h5>
                          <h2 className='detailedheadervalue'>
                            {selectedCard.key}
                          </h2>
                        </Col>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}
                        ></Col>
                      </Row>
                      <Divider style={{ margin: '16px 0' }} />
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <h5>Phrase</h5>
                          {/* <p>{selectedCard.phrase}</p> */}
                          <div
                            style={{
                              width: '100%',
                              minHeight: '333px',
                              border: 'none !important',

                              padding: '5px',
                              overflowY: 'auto',
                              whiteSpace: 'pre-wrap' // Allow text to wrap
                            }}
                            contentEditable={false} // Set to true if you want to allow editing
                            dangerouslySetInnerHTML={{
                              __html: phraseWithoutPTags
                            }} // Render HTML content
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </CustomModal>

            <Keytophraseduplication
              title='Import Key-to-Phrases'
              visible={errorModalVisible}
              onCancel={toggleErrorModal}
              footer={null}
              centered
            >
              <div style={{ flex: 1, marginBottom: '20px' }}>
                The names of certain key-to-phrases you are attempting to import
                are already present in your collection. Please choose whether to
                skip or replace the conflicting key-to-phrases.
              </div>
              <div
                className='warning-container'
                style={{ marginBottom: '20px' }}
              >
                <ExclamationCircleOutlined style={{ color: 'orange' }} />
                <div className='warning-message'>
                  Replacing will permanently remove those key-to-phrases from
                  your Collection
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px'
                }}
              >
                <AddButton
                  type='primary'
                  shape='round'
                  onClick={toggleErrorModal}
                >
                  Change selection
                </AddButton>
                <AddButton shape='round' onClick={handleSkip}>
                  Skip those
                </AddButton>
                <Button danger shape='round' onClick={handleReplace}>
                  Replace those
                </Button>
              </div>
            </Keytophraseduplication>
          </div>
        </TitleRow>
        <BreadCrumbComponent items={BreadCrubItems} />

        <div>
          <Topdivide />
          <Row>
            <KeytophraseMineCol span={9}>
              <SubmitCol>
                <CardTitle>My Collection</CardTitle>

                <SearchInputStyle
                  placeholder='Search Keys and Phrases'
                  prefix={<SearchOutlined />}
                  onChange={handleSearch}
                  style={{
                    marginBottom: '10px',
                    width: '100%'
                  }}
                />

                <Divider></Divider>
                <DndProvider backend={HTML5Backend}>
                  <DnDColumn
                    style={{ overflow: 'scroll' }}
                    onScroll={handleScroll}
                  >
                    {loading ? (
                      <Spinner height={'70vh'} />
                    ) : cannedTextMine.length == 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flex: 1 // Make this div occupy remaining space vertically
                        }}
                      >
                        No data available
                      </div>
                    ) : (
                      <div>
                        {returnItemsForColumn1(KEY_TO_PHRASE)}
                        {loadingMineScroll ? <Spinner height={'20vh'} /> : null}
                      </div>
                    )}
                  </DnDColumn>
                </DndProvider>
              </SubmitCol>
            </KeytophraseMineCol>

            <ActiveSectionCol span={14} className='activesection'>
              <h4 className='active-k2p-head'>{ACTIVE_KEY_TO_PHRASE}</h4>
              <SubmitCol
                bordered={false}
                style={{
                  backgroundColor: '#edeff4',
                  overflowY: 'scroll'
                }}
                onScroll={handleScrollActive}
              >
                {loader ? (
                  <Spinner height={'70vh'} />
                ) : (
                  <>
                    <ActiveKeytophraseCol
                      bordered={false}
                      style={{ backgroundColor: '#edeff4' }}
                    >
                      {loadingActive ? (
                        <Spinner height={'70vh'} />
                      ) : (
                        <DndProvider backend={HTML5Backend}>
                          <Column title={ACTIVE_KEY_TO_PHRASE}>
                            {cannedTextActive.length == 0
                              ? 'No data available'
                              : returnItemsForColumn(ACTIVE_KEY_TO_PHRASE)}
                            {loadingActiveScroll ? (
                              <Spinner height={'20vh'} />
                            ) : null}
                          </Column>
                        </DndProvider>
                      )}
                    </ActiveKeytophraseCol>
                  </>
                )}
              </SubmitCol>
            </ActiveSectionCol>
          </Row>
        </div>

        {showSelectedPhrase && (
          <CustomModalforAddk2p
            title={selectedItem ? 'Edit Key' : 'Add Key'}
            // closable={false}
            footer={null}
            // onCancel={handleCancel}
            visible={showSelectedPhrase}
            // width={'60%'}
            // destroyOnClose={true}
            onCancel={toggleTable}
          >
            <AddKeyToPhraseForm
              setSubmitDisabled={setSubmitDisabled}
              selectedItem={selectedItem}
              k2pMapping={k2pMapping}
              selectedItemColumn={selectedItemColumn}
              selectedPhrase={selectedPhrase}
              submitHandler={submitHandler}
              selectedFields={selectedFields}
              handleCheck={handleCheck}
              treeVisible={treeVisible}
              toggleTreeVisibility={toggleTreeVisibility}
              submitLoading={submitLoading}
              handleDeleteClick={handleDeleteClick}
              handleActiveClick={handleActiveClick}
              handleRemoveFromSelected={handleRemoveFromSelected}
              dynamicEndPoints={dynamicEndPoints}
              toggleActive={toggleActive}
              loadingTree={loadingTree}
              handleActiveClickChange={handleActiveClickChange}
              toActive={toActive}
              handleFormValuesChange={handleFormValuesChange}
              submitDisabled={submitDisabled}
              handleMineDelete={handleMineDelete}
              deleteModal={deleteModal}
              updatedK2pMappingkeys={updatedK2pMappingkeys}
            />
          </CustomModalforAddk2p>
        )}
      </ContainerRow>
    </>
  );
};

export default KeyToPhrase;
